<template>
  <div>
    <el-row :gutter="20" class="mt16" v-if="list && list.length > 0">
      <el-col :span="12" class="pointer mb16" v-for="(item, index) in list" :key="index">
        <div class="post">
          <div class="flex between-center">
            <div class="pname">{{item.title}}</div>
            <div class="price">{{item.priceDesc}}</div>
          </div>
          <!-- <div class="tag-list mt8">
            <div class="tag" v-for="(tag, index) in item.tags" :key="index">{{tag}}</div>
          </div> -->
          <!-- <div class="flex between-center mt16">
            <div class="flex items-center">
              <img class="mer-logo" src="@/assets/images/company.png"/>
              <span class="mer-name">{{item.merchantName}}</span>
            </div>
            <div class="btn-enroll">立即报名</div>
          </div> -->
          <div class="flex between-center mt12">
            <div class="fs14" v-if="item.auditResult">
              <div class="flex items-center">
                <div>提交时间：{{item.auditResult.gmtCreate}}</div>
                <!-- <div class="theme-text">{{item.auditResult.statusDesc}}</div> -->
              </div>
              <div class="flex items-center mt12" v-if="item.auditResult.status != 0">
                <div>审核时间：{{item.auditResult.auditTime}}</div>
                <div class="ml8">审核员：{{item.auditResult.auditor}}</div>
              </div>
            </div>
            <div :class="getStatusClass(item.auditStatus)">{{item.auditStatusDesc}}</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-empty v-else description="暂无相关数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getStatusClass(status) {
      /**
       * 
        TO_AUDIT(0, "审核中"),
        RECRUIT(1, "招聘中"),
        PAUSE(2, "暂停招聘"),
        FINISH(3, "结束招聘"),
        REJECT(4, "审核不通过"),
        DELETE(5, "已删除"),
        //修改审核中 针对上过线的职位 区别是不能改行业分类 这两个状态存在于postModify对象
        MODIFY_AUDIT(6, "修改审核中"),
        MODIFY_REJECT(7, "修改未通过"),
        //权益不足 进入待发布状态
        WAITING_PUB(8, "待发布"),
        FREEZE(9, "强制暂停"),
       */
      if(status == 1) {
        return 'theme-text'
      }
      if(status == 0 || status == 2 || status == 6 || status == 8) {
        return 'yellow-text'
      }
      if(status == 3 || status == 4 || status == 5 || status == 7 || status == 9) {
        return 'red-text'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.post {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px 24px;
  min-height: 108px;
  .pname {
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .price {
    font-size: 16px;
    font-weight: bold;
    color: var(--theme-color-red);
    margin-left: 10px;
  }
  .tag-list {
    display: flex;
    align-items: center;
    font-size: 14px;
    .tag {
      height: 26px;
      line-height: 26px;
      padding: 0 8px;
      border-radius: 4px;
      background-color: #F3F7F8;
      margin-right: 8px;
    }
  }
  .mer-logo {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    margin-right: 4px;
  }
  .mer-name {
    color: #99A3A3;
  }
  .btn-enroll {
    height: 30px;
    line-height: 30px;
    border-radius: 8px;
    border: 1px solid #99A3A3;
    color: #99A3A3;
    padding: 0 12px;
  }
}
</style>